import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { useGetItemsFromMachines, useGetSlotsRealtime } from './RefreshMachineStatus';
import { Box, Card, CardContent, colors } from '@material-ui/core';
import LockerItem from './LockerItem';

const useStyles = makeStyles(theme => ({
    row: {
      display: 'flex',
      marginBottom: theme.spacing(3),
    },
    rowLabel: {
      marginBottom: theme.spacing(1),
    },
    label: {
      width: '100%',
      marginBottom: 20,
      textAlign: 'right',
    },
    card: {
      overflowY: 'hidden',
      overflowX: 'scroll',
    },
    legendLabel: {
      minWidth: 80,
      minHeight: 30,
      display: 'inline-block',
      marginBottom: 2,
      marginRight: 10,
      fontSize: 14,
      fontWeight: 'bold',
    },
    legend: {
      minWidth: 80,
      minHeight: 30,
      display: 'inline-block',
      border: '1px solid',
      borderRadius: 10,
      marginRight: 7,
      marginBottom: 2,
      padding: 6,
      textAlign: 'center',
      fontSize: 12,
  
      '&.occupied': {
        backgroundColor: colors.green['A100'],
      },
  
      '&.empty': {
        backgroundColor: colors.blue[200],
      },
  
      '&.notInitialized': {
        backgroundColor: colors.grey[100],
      },
  
      '&.error': {
        backgroundColor: colors.red[200],
      },
  
      '&.refill': {
        backgroundColor: colors.orange[200],
      },
    },
}));

const reformatData = (numOfRow, slotList) => {
    const dataToReturn = [];
    const slotIDList = Object.keys(slotList);

    for(let i = 0; i < numOfRow; i++) {
        dataToReturn[i] = [];
    }

    slotIDList.forEach(idKey => {
        let dataToPass = slotList[idKey];
        dataToReturn[dataToPass.data.yPhysical - 1][dataToPass.data.xPhysical - 1] = dataToPass;
    });

    return dataToReturn;
};

const LockerGrid = ({ state, machineId, machineMode, boardData }) => {
    const classes = useStyles();
    const { data, loaded } = state;
    const isCompatMachine = machineMode === "VE-E1208N" || machineMode === "VE-E1208C";
    const itemsOnBoard = useGetItemsFromMachines(machineId, boardData.id);
    const realtimeSlots = useGetSlotsRealtime(machineId);
    let COL_NUM = boardData.xColumn;
    let ROW_NUM = boardData.yRow;

    if(!itemsOnBoard.loaded || !realtimeSlots.loaded) return null;
    
    const formattedData = reformatData(ROW_NUM, itemsOnBoard.items);

    const Row = ({ rowIndex }) => {
        return (
            <div>
                <Box className="row">
                    {Array.from({ length: COL_NUM }).map((_, i) => {
                        const slotIndex = rowIndex * COL_NUM + i;
                        const slotNumber = slotIndex + 1;
                        const slotInformation = formattedData?.[rowIndex]?.[i] || null;
                        const slotData = slotInformation?.id ? realtimeSlots.data[slotInformation.id] : {};

                        
                        if(i >= 10) return null;
                        
                        return (
                            <LockerItem
                                key={`slot-${slotIndex}-${i}`}
                                slotData={slotData}
                                slotNumber={slotNumber}
                                slotInformation={slotInformation}
                                machineId={machineId}
                                boardId={boardData.id}
                                disable={false}
                                is12slots={false}
                            />
                        )
                    })}
                </Box>
            </div>
        );
    };

    const CompactRow = ({ rowIndex }) => {
        return (
            <div>
                <Box className="row">
                    {Array.from({ length: COL_NUM }).map((_, i) => {
                        const slotIndex = rowIndex * COL_NUM + i;
                        const slotNumber = slotIndex + 1;
                        const slotInformation = formattedData?.[rowIndex]?.[i] || null;
                        const slotData = realtimeSlots.data[slotInformation.id] || {};

                        if(i >= 12) {
                            return null;
                        } else {
                            if(rowIndex > 2 && rowIndex < 6 && i > 7 && i < 13) {
                                return (
                                    <LockerItem
                                        key={`compat-slot-${slotIndex}-${i}`}
                                        slotData={slotData}
                                        slotNumber={slotNumber}
                                        machineId={machineId}
                                        boardId={boardData.id}
                                        disable={true}
                                        is12slots={true}
                                    />
                                )
                            } else {
                                return (
                                    <LockerItem
                                        key={`compat-slot-${slotIndex}-${i}`}
                                        slotData={slotData}
                                        slotNumber={slotNumber}
                                        machineId={machineId}
                                        boardId={boardData.id}
                                        disable={true}
                                        is12slots={true}
                                    />
                                );
                            }
                        }

                    })}
                </Box>
            </div>
        );
    };

    return (
        <div>
            <div className="label">
                <Box className="slot-status">Legends:</Box>
                <Box className={`occupied`}>Occupied</Box>
                <Box className={`emptyBoard`}>Empty</Box>
                <Box className={`refill`}>Refill</Box>
                <Box className={`nonInitialized`}>Not Initialized</Box>
                <Box className={`error`}>Locker Error</Box>
            </div>

            <Card className="card">
                <CardContent>
                    {Array.from({ length: ROW_NUM }, (_, i) => (
                        isCompatMachine ? <CompactRow rowIndex={i} key={`compat-row-${i}`} /> : <Row rowIndex={i} key={`row-${i}`} />
                    ))}
                </CardContent>
            </Card>
        </div>
    )
};

export default LockerGrid;
