import { Button, CardActions, CardContent, CardHeader, Grid, InputAdornment, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react'
import { ImageField, ImageInput, NumberInput, ReferenceInput, required, SelectInput, SimpleForm, TextInput, useDataProvider } from 'react-admin';
import { useNavigate, useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { onAuthStateChanged } from 'firebase/auth';
import { auth, db } from '../../firebase';
import { useFormContext } from 'react-hook-form';
import { ClientHas } from '../Machines/RefreshMachineStatus';
import MROAccessGroupInput from '../Machines/MROAccessGroupInput';
import { doc, setDoc } from 'firebase/firestore';
import _extendLayout from '../../layout/_extendLayout';

const useStyles = makeStyles(theme => ({
    productImage: {
      objectFit: 'contain',
      width: '100%',
      height: 280,
      maxHeight: 'none',
    },
}));

const price = msg => (value) => {
    const regex = /^\d*(.\d{0,2})?$/;
    if(!regex.test(value)) return msg || "Invalid";
};

const CategoryInput = ({ client, defaultValue }) => {
    return (
        <ReferenceInput
            key={client}
            source="category"
            reference="categories"
            filter={{ client: client || "" }}
            filterToQuery={() => {}}
            fullWidth
            perPage={99999}
        >
            <SelectInput optionText="name" fullWidth validate={required("Please select a category.")} defaultValue={defaultValue} />
        </ReferenceInput>
    );
};

const LimitationRuleInput = ({ client, defaultValue }) => {
    return (
        <ReferenceInput
            key={client}
            source="limitationRule"
            reference="limitationRules"
            filter={{ client: client || "" }}
            filterToQuery={() => {}}
            perPage={99999}
            fullWidth
            resettable={true}
        >
            <SelectInput optionText="name" fullWidth defaultValue={defaultValue} />
        </ReferenceInput>
    );
};

const ProductEdit = () => {



    const { id } = useParams();
    const classes = useStyles();
    const dataProvider = useDataProvider();
    const form = useFormContext();
    const navigate = useNavigate();

    const [proDetails, setProDetails] = useState(null);
    const [isAdmin, setIsAdmin] = useState(false);
    const [clientId, setClientId] = useState(null);
    const [mroAccess, setMroAccess] = useState([]);

    useEffect(() => {
        const checkAuth = onAuthStateChanged(auth, async (user) => {
            if(user) {
                await getCurrentUser(user.uid);
                await getProductById();
            }
        });
        return () => checkAuth();
    }, []);

    const getProductById = async () => {
        try {
            const res = await dataProvider.getOne("products", { id });
            setProDetails(res.data)
        } catch (err) {
            console.log(err)
        }
    };

    const getCurrentUser = async (userId) => {
        try {
            const res = await dataProvider.getOne("users", { id: userId });
            setIsAdmin(res.data.role === "admin");
            setClientId(res.data.client.id);
        } catch (err) {
            console.log(err);
        }
    };

    const handleClientChange = () => {
        const categoryState = form.getFieldState("category");
        if(categoryState) {
            form.setValue("category", "");
        }

        const mroAccessState = form.getFieldState("mroAccessGroup");
        if(mroAccessState) {
            form.setValue("mroAccessGroup", []);
        }
    };

    const handleSave = async (datas) => {
        let obj = {
            category: datas.category,
            client: datas.client,
            description: datas.description ?? "",
            image: {
                src: datas.image ?? "",
            },
            limitationRule: datas.limitationRule ?? null,
            name: datas.name ?? "",
            priceCash: datas.priceCash ?? 0,
            priceCredit: datas.priceCredit ?? 0,
            serialNumber: datas.serialNumber ?? "",
            sku: datas.sku ?? "",
            mroAccessGroup: mroAccess ?? [],
        };

        try{
            const docRef = doc(db, "products", id);

            await setDoc(docRef, obj, { merge: true });

            alert("Product saved successfully!");
            navigate("/products")
        } catch (err) {
            console.log("Error saving to Firebase:", err);
            alert("Failed to save product. Please try again.")
        }
    };

    if(!proDetails) {
        return <p>Loading...</p>
    }
    
    return (
        <>
            <_extendLayout>
                <CardHeader title="Edit Product" />
                <CardContent>
                    <p>Product Details</p>
                    <SimpleForm toolbar={null} onSubmit={handleSave}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} md={4}>
                                <Typography gutterBottom variant="h6">
                                    Product Image
                                </Typography>
                                <ImageInput
                                    source="image"
                                    accept="image/*"
                                    label=""
                                    fullWidth
                                    defaultValue={proDetails.image.src}
                                >
                                    <ImageField source="src" record={{ src: proDetails.image.src }} classes={{ image: classes.productImage }}  />
                                </ImageInput>
                                <Typography
                                    variant="body2"
                                    color="textSecondary"
                                    style={{ marginTop: "8px" }}
                                >
                                    Recommended size: 720 x 720 px. Display ratio 1:1 png or jpg
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={8}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12}>
                                        <TextInput label="Product Name" source="name" fullWidth validate={required("Please enter a name.")} defaultValue={proDetails.name ?? ""} />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextInput label="Description" source="description" fullWidth defaultValue={proDetails.description ?? ""} />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <TextInput label="SKU" source="sku" fullWidth defaultValue={proDetails.sku ?? ""} />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <TextInput label="Serial Number" source="serialNumber" fullWidth defaultValue={proDetails.serialNumber ?? ""} />
                                    </Grid>
                                    <Grid item xs={12}>
                                        {isAdmin ? (
                                            <ReferenceInput
                                                source="client"
                                                reference="clients"
                                                filterToQuery={() => {}}
                                                onChange={handleClientChange}
                                            >
                                                <SelectInput
                                                    optionText="companyName"
                                                    validate={required("Please select a client.")}
                                                    fullWidth
                                                    defaultValue={proDetails.client}
                                                />
                                            </ReferenceInput>
                                        ) : (
                                            <TextInput
                                                source="client"
                                                label="Client"
                                                fullWidth
                                                defaultValue={clientId}
                                                disabled
                                                type="hidden"
                                                style={{ display: "none" }}
                                            />
                                        )}
                                    </Grid>
                                    {/* category input */}
                                    {proDetails.client && (
                                        <>
                                            <Grid item xs={12}>
                                                <CategoryInput client={proDetails.client || clientId} defaultValue={proDetails.category} />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <LimitationRuleInput client={proDetails.client || clientId} defaultValue={proDetails.limitationRule} />
                                            </Grid>
                                        </>
                                    )}
                                    <Grid item xs={12} md={6}>
                                        <NumberInput
                                            source="priceCash"
                                            label="Price (RM)"
                                            fullWidth
                                            defaultValue={proDetails.priceCash ?? ""}
                                            validate={[
                                                required("Please enter a price"),
                                                price("Please enter a valid price with no more than two decimals.")
                                            ]}
                                            InputProps={{
                                                startAdornment: <InputAdornment position="start">RM</InputAdornment>
                                            }}
                                        />
                                    </Grid>
                                    {proDetails.client && (
                                        <ClientHas client={proDetails.client} module="mro" fallback={null}>
                                            <>
                                                <Grid item xs={12} md={6}>
                                                    <NumberInput
                                                        source="priceCredit"
                                                        label="Price (Credit)"
                                                        fullWidth
                                                        defaultValue={proDetails.priceCredit ?? ""}
                                                        validate={[
                                                            required("Please enter credit price"),
                                                            price("Please enter a valid credit price with no more than two decimals.")
                                                        ]}
                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <MROAccessGroupInput 
                                                        client={proDetails.client}
                                                        value={proDetails.mroAccessGroup || []} // Pass the current value or an empty array
                                                        initialValue={proDetails.mroAccessGroup || []}
                                                        onValueChange={(updatedValue) => {
                                                            setMroAccess(updatedValue)
                                                        }}
                                                    />
                                                </Grid>
                                            </>
                                        </ClientHas>
                                    )}
                                </Grid>
                            </Grid>
                        </Grid>
                        {/* <Grid item xs={12} md={4}>
                            <Typography gutterBottom variant="h6">
                                Product Infographic
                            </Typography>
                            <ImageInput source="infographic" accept="image/*" label="" helperText="Recommended size: 1080 x 1280 px.">
                                    <ImageField source="src" classes={{ image: classes.productImage }} />
                            </ImageInput>
                        </Grid> */}
                        <CardActions>
                            <Button type="submit" variant="contained" color="primary">
                                Save
                            </Button>
                            <Button onClick={() => navigate(`/products`)} variant="contained">
                                Back
                            </Button>
                        </CardActions>
                    </SimpleForm>
                </CardContent>
            </_extendLayout>
        </>
    );
}

export default ProductEdit;
