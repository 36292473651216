import { Button, CircularProgress } from '@material-ui/core';
import React from 'react'
import { useRefreshMachineStatus } from './RefreshMachineStatus';

const RefreshStatusButton = ({ record }) => {
    const [refreshing, refresh] = useRefreshMachineStatus();

    const handleRefresh = async () => {
        if(record && record.id) {
            await refresh(record.id);
            alert(record.id)
        }
    };

    return (
        <>
            <Button
                label="Refresh Status"
                onClick={handleRefresh}
                disabled={refreshing}
            >
                {refreshing ? <CircularProgress size={20} /> : "Refresh"}
            </Button>
        </>
    )
}

export default RefreshStatusButton;