import React from 'react'
import { Box, colors, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { generatePath, useNavigate } from 'react-router-dom';
import moment from 'moment/moment';

const LockerStatus = {
    Available: 1,
    PendingDeposit: 2,
    PendingCollection: 3,
    Collecting: 4,
    AdminCheck: 5,
    Reserved: 6,
    Completed: 100,
    ExpiredBeforeDeposit: 102,
    ExpiredBeforeCollection: 103,
};

const SLOT_STATUS = LockerStatus;

const useStyles = makeStyles(theme => ({
  root: props => {
    const { status } = props;
    let backgroundColor;
    let cursor = 'pointer';
    if (status === SLOT_STATUS.DISABLE) {
      backgroundColor = colors.green['A100'];
      cursor = '';
    }
    if (status === SLOT_STATUS.Available) backgroundColor = colors.green['A100'];
    if (status === SLOT_STATUS.PendingDeposit) backgroundColor = colors.blue[100];
    if (status === SLOT_STATUS.PendingCollection) backgroundColor = colors.orange[100];
    if (status === SLOT_STATUS.Collecting) backgroundColor = colors.blue[200];
    if (status === SLOT_STATUS.Reserved) backgroundColor = colors.yellow[100];
    if (status === SLOT_STATUS.Completed) backgroundColor = colors.green[100];
    if (status === SLOT_STATUS.ExpiredBeforeDeposit) backgroundColor = colors.red[200];
    if (status === SLOT_STATUS.ExpiredBeforeCollection) backgroundColor = colors.red[300];

    return {
        position: 'relative',
        minWidth: '130px',
        minHeight: '130px',
        maxWidth: '130px',
        maxHeight: '130px',
        border: `1px solid #808080`,
        borderRadius: "4px",
        padding: "10px",
        fontSize: 12,
        textAlign: 'center',
        cursor,
        backgroundColor,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
    };
  },
  text: {},
  textBold: {
    fontWeight: 'bold',
  },
  quantity: {},
  statusIndicator: props => {
    const { status } = props;
    let backgroundColor = colors.green[500];
    if (status === SLOT_STATUS.Available) backgroundColor = colors.green['A100'];
    if (status === SLOT_STATUS.PendingDeposit) backgroundColor = colors.blue[100];
    if (status === SLOT_STATUS.PendingCollection) backgroundColor = colors.orange[100];
    if (status === SLOT_STATUS.Collecting) backgroundColor = colors.blue[200];
    if (status === SLOT_STATUS.AdminCheck) backgroundColor = colors.grey[300];
    if (status === SLOT_STATUS.Reserved) backgroundColor = colors.yellow[100];
    if (status === SLOT_STATUS.ExpiredBeforeDeposit) backgroundColor = colors.red[200];
    if (status === SLOT_STATUS.ExpiredBeforeCollection) backgroundColor = colors.red[300];

    return {
      position: 'absolute',
      width: 5,
      height: 5,
      borderRadius: 10,
      top: 5,
      right: 5,
      display: 'block',
      backgroundColor,
    };
  },
}));

const Doc2UsLockerItem = ({ slotData, slotInformation, machineId, boardId, disable, is12slots }) => {
    
    const classes = useStyles({ is12slot: is12slots });
    const navigate = useNavigate();
    const slotId = slotInformation?.id || "";
    const slotName = slotInformation?.data?.name || "";
    const { expiryDate, skus, status, receiverName } = slotData;

    let localTimeOfExpiry = null;

    if(expiryDate) {
        let utcOffset = moment.utc(expiryDate).toDate();
        let localTimeOfExpiry = moment(utcOffset).local().format("DD/MM/YYYY HH:mm");
    }

    const renderProduct = () => {
        return "";
    };

    const truncate = (input) => {
        if(input.length > 13) {
            return input.substring(0, 13) + "...";
        }
        return input;
    };

    if(disable) {
        return <div className={classes.root}></div>
    };

    return (
        // <div
        //     className={classes.root}
        //     onClick={() => {
        //         const path = generatePath(`/machines/:machineId/:boardId/lockers-doc2us/:slotId`, { machineId, boardId, slotId });
        //         navigate(path);
        //     }}
        // >
        //     <Box>
        //         <Box className={classes.text}>{slotName}</Box>
        //         <Box className={classes.textBold}>{receiverName ? receiverName : ""}</Box>
        //         <Box className={classes.textBold} mt={1}>
        //             {truncate(renderProduct())}
        //         </Box>
        //         <Box className={classes.text}>{localTimeOfExpiry ? localTimeOfExpiry : ""}</Box>
        //     </Box>
        // </div>
        <Box
            onClick={() => { const path = generatePath("/machines/:machineId/:boardId/lockers-doc2us/:slotId", { machineId, boardId, slotId }); navigate(path) }}
            className={`slot ${status === SLOT_STATUS.Available ? "available" : status === SLOT_STATUS.PendingDeposit ? "pendingDeposit" : status === SLOT_STATUS.PendingCollection ? "pendingCollection" : status === SLOT_STATUS.Collecting ? "collecting" : status === SLOT_STATUS.AdminCheck ? "adminCheck" : status === SLOT_STATUS.Reserved ? "reserved" : status === SLOT_STATUS.ExpiredBeforeDeposit ? "expiredBeforeDeposit" : status === SLOT_STATUS.ExpiredBeforeCollection ? "expiredBeforeCollection" : "available"}`}
            >
            <Typography variant="body2" className="slot-label">{slotName}</Typography>
            <Typography variant="body1" fontWeight="bold" className="product-name" mt={1}>
                {renderProduct()}
            </Typography>
        </Box>
    )
}

export default Doc2UsLockerItem;
