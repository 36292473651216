import { Button, CardActions, CardContent, CardHeader, Grid, InputAdornment, Typography } from '@material-ui/core';
import { onAuthStateChanged } from 'firebase/auth';
import React, { useEffect, useState } from 'react'
import { ImageField, ImageInput, NumberInput, ReferenceInput, required, SelectInput, SimpleForm, TextInput, useDataProvider } from 'react-admin';
import { app, auth, db } from '../../firebase';
import { makeStyles } from '@material-ui/core/styles';
import { useNavigate } from 'react-router-dom';
import { ClientHas } from '../Machines/RefreshMachineStatus';
import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage";
import MROAccessGroupInput from '../Machines/MROAccessGroupInput';
import { addDoc, collection, doc, setDoc } from 'firebase/firestore';

const useStyles = makeStyles(theme => ({
    productImage: {
      objectFit: 'contain',
      width: '100%',
      height: 280,
      maxHeight: 'none',
    },
}));

const price = msg => (value) => {
    const regex = /^\d*(.\d{0,2})?$/;
    if(!regex.test(value)) return msg || "Invalid";
};

const CategoryInput = ({ client, defaultValue }) => {
    return (
        <ReferenceInput
            key={client}
            source="category"
            reference="categories"
            filter={{ client: client || "" }}
            filterToQuery={() => {}}
            fullWidth
            perPage={99999}
        >
            <SelectInput optionText="name" fullWidth validate={required("Please select a category.")}  />
        </ReferenceInput>
    );
};

const LimitationRuleInput = ({ client, defaultValue }) => {
    return (
        <ReferenceInput
            key={client}
            source="limitationRule"
            reference="limitationRules"
            filter={{ client: client || "" }}
            filterToQuery={() => {}}
            perPage={99999}
            fullWidth
            resettable={true}
        >
            <SelectInput optionText="name" fullWidth />
        </ReferenceInput>
    );
};

const ProductCreate = () => {




    const navigate = useNavigate();
    const classes = useStyles();
    const dataProvider = useDataProvider();

    const [isAdmin, setIsAdmin] = useState(false);
    const [clientId, setClientId] = useState(null);
    const [mroAccess, setMroAccess] = useState([]);
    const [categories, setCategories] = useState([]);
    const [limitationRules, setLimitationRules] = useState([]);

    useEffect(() => {
        const checkAuth = onAuthStateChanged(auth, async (user) => {
            if(user) {
                await getCurrentUser(user.uid);
            }
        });
        return () => checkAuth();
    }, []);

    const getCurrentUser = async (userId) => {
        try {
            const res = await dataProvider.getOne("users", { id: userId });
            setIsAdmin(res.data.role === "admin");
            setClientId(res.data.client.id);
            
            await getCatogeryList(res.data.client.id);
            await getLimitationList(res.data.client.id);
        } catch (err) {
            console.log(err);
        }
    };

    const getCatogeryList = async (clientId) => {
        try {
            const res = await dataProvider.getList("categories", {
                filter: { "client": clientId },
                sort: { field: "createdAt", order: "DESC" },
            });
            
            const categoryChoices = res.data.map((category) => ({
                id: category.id,
                name: category.name,
            }));
            setCategories(categoryChoices);
        } catch (err) {
            console.log(err);
        }
    };

    const getLimitationList = async (clientId) => {
        try {
            const res = await dataProvider.getList("limitationRules", {
                filter: { "client": clientId },
                sort: { field: "createdAt", order: "DESC" },
            });
            
            const limitationChoices = res.data.map((limitation) => ({
                id: limitation.id,
                name: limitation.name
            }));
            setLimitationRules(limitationChoices);
        } catch (err) {
            console.log(err);
        }
    };

    const handleSave = async (d) => {
        const storage = getStorage(app);
        let imageUrl = d.image?.src || "";
        let documentId = d.id || null;


        try {
            if(!documentId) {
                const docRef = await addDoc(collection(db, "products"), {});
                documentId = docRef.id;
            }

            if(d.image?.rawFile) {
                const file = d.image.rawFile;
                const fileName = `products/${documentId}/${file.name}`;
                const storageRef = ref(storage, fileName);

                const snapshot = await uploadBytes(storageRef, file);
                imageUrl = await getDownloadURL(snapshot.ref);
            }

            const updatedData = {
                category: d.category,
                client: d.client,
                description: d.description ?? "",
                image: {
                    src: imageUrl,
                },
                limitationRule: d.limitationRule ?? null,
                name: d.name ?? "",
                priceCash: d.priceCash ?? 0,
                priceCredit: d.priceCredit ?? 0,
                serialNumber: d.serialNumber ?? "",
                sku: d.sku ?? "",
                mroAccessGroup: mroAccess ?? [],
            };

            const docRef = doc(db, "products", documentId);
            await setDoc(docRef, updatedData, { merge: true });

            alert("Product saved successfully!");
            navigate("/products");
        } catch (err) {
            console.log("save error ", err);
        }
    };

    return (
        <>
            <CardHeader title="Create Product" />
            <CardContent>
                <p>Product Details</p>
                <SimpleForm toolbar={null} onSubmit={handleSave}>
                    <Grid container spacing={3}>
                        <Grid item mb={2} xs={12} md={4}>
                            <Typography gutterBottom variant="h6">
                                Product Image
                            </Typography>
                            <ImageInput
                                source="image"
                                accept="image/*"
                                label=""
                                fullWidth
                            >
                                <ImageField source="src" classes={{ image: classes.productImage }}  />
                            </ImageInput>
                        </Grid>
                        <Grid item xs={12} md={8}>
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <TextInput label="Product Name" source="name" fullWidth validate={required("Please enter a name.")} />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextInput label="Description" source="description" fullWidth />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextInput label="SKU" source="sku" fullWidth />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextInput label="Serial Number" source="serialNumber" fullWidth />
                                </Grid>
                                <Grid item xs={12}>
                                    {isAdmin ? (
                                        <ReferenceInput
                                            source="client"
                                            reference="clients"
                                            filterToQuery={() => {}}
                                            fullWidth
                                            // onChange={handleClientChange}
                                        >
                                            <SelectInput
                                                optionText="companyName"
                                                fullWidth
                                                validate={required("Please select a client.")}
                                            />
                                        </ReferenceInput>
                                    ) : (
                                        <TextInput
                                            source="client"
                                            label="Client"
                                            fullWidth
                                            defaultValue={clientId}
                                            disabled
                                            type="hidden"
                                            style={{ display: "none" }}
                                        />
                                    )}
                                </Grid>
                                {/* category input */}
                                <Grid item xs={12}>
                                    <SelectInput
                                        source="category"
                                        label="Category"
                                        choices={categories}
                                        fullWidth
                                        validate={(required("Please select a category."))}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <SelectInput
                                        source="limitationRule"
                                        label="Limitation Rule"
                                        choices={limitationRules}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <NumberInput
                                        source="priceCash"
                                        label="Price (RM)"
                                        fullWidth
                                        validate={[
                                            required("Please enter a price"),
                                            price("Please enter a valid price with no more than two decimals.")
                                        ]}
                                        InputProps={{
                                            startAdornment: <InputAdornment position="start">RM</InputAdornment>
                                        }}
                                    />
                                </Grid>
                                <ClientHas client={clientId} module="mro" fallback={null}>
                                    <>
                                        <Grid item xs={12} md={6}>
                                            <NumberInput
                                                source="priceCredit"
                                                label="Price (Credit)"
                                                fullWidth
                                                validate={[
                                                    required("Please enter credit price"),
                                                    value => (/^\d*(\.\d{0,2})?$/.test(value) ? undefined : 'Invalid credit price format')
                                                ]}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <MROAccessGroupInput 
                                                client={clientId}
                                                value={mroAccess} // Pass the current value or an empty array
                                                initialValue={[]}
                                                onValueChange={(updatedValue) => setMroAccess(updatedValue)}
                                            />
                                        </Grid>
                                    </>
                                </ClientHas>
                            </Grid>
                        </Grid>
                    </Grid>
                    <CardActions>
                        <Button type="submit" variant="contained" color="primary">
                            Save
                        </Button>
                        <Button onClick={() => navigate(`/products`)} variant="contained">
                            Back
                        </Button>
                    </CardActions>
                </SimpleForm>
            </CardContent>
        </>
    );
};

export default ProductCreate;
