import { Box, colors, Typography } from '@material-ui/core';
import React from 'react'
import { generatePath, useNavigate } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';

const SLOT_STATUS = {
    DISABLE: 0,
    NOTINITIALIZED: 1,
    ERROR: 2,
    REFILL: 3,
    OCCUPIED: 4,
    EMPTY: 5,
};
  
const useStyles = makeStyles(theme => ({
    root: props => {
        const { status } = props;
        let backgroundColor;
        let cursor = 'pointer';
        if (status === SLOT_STATUS.DISABLE) {
            backgroundColor = 'white';
            cursor = '';
        }
        if (status === SLOT_STATUS.ERROR) backgroundColor = colors.red[200];
        if (status === SLOT_STATUS.REFILL) backgroundColor = colors.orange[200];
        if (status === SLOT_STATUS.OCCUPIED) backgroundColor = colors.green['A100'];
        if (status === SLOT_STATUS.NOTINITIALIZED) backgroundColor = colors.grey[100];
        if (status === SLOT_STATUS.EMPTY) backgroundColor = colors.blue[200];
  
        return {
            position: 'relative',
            minWidth: '130px',
            minHeight: '130px',
            maxWidth: '130px',
            maxHeight: '130px',
            border: `1px solid #808080`,
            borderRadius: "4px",
            padding: "10px",
            fontSize: 12,
            textAlign: 'center',
            cursor,
            backgroundColor,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
        };
    },
    text: {},
    textBold: {
      fontWeight: 'bold',
    },
    quantity: {},
    statusIndicator: props => {
      const { status } = props;
      let backgroundColor = colors.green[500];
      if (status === SLOT_STATUS.DISABLE) backgroundColor = colors.grey[50];
      if (status === SLOT_STATUS.NOTINITIALIZED) backgroundColor = colors.grey[500];
      if (status === SLOT_STATUS.ERROR) backgroundColor = colors.red[500];
      if (status === SLOT_STATUS.REFILL) backgroundColor = colors.orange[500];
  
      return {
        position: 'absolute',
        width: 5,
        height: 5,
        borderRadius: 10,
        top: 5,
        right: 5,
        display: 'block',
        backgroundColor,
      };
    },
}));

const LockerItem = ({ slotData = {}, slotInformation, slotNumber, machineId, boardId, disable, is12slots }) => {
    
    const { totalQuantity = 0, error = false, product = {} } = slotData;
    
    const slotId = slotInformation?.id || ''; //should be 61 instead of 1
    const slotName = slotInformation?.data?.name || '';

    const getStatus = () => {
        if (disable) return SLOT_STATUS.DISABLE;
        if (!product || Object.keys(product).length === 0) return SLOT_STATUS.NOTINITIALIZED;
        if (error) return SLOT_STATUS.ERROR;
        if (totalQuantity !== undefined && totalQuantity <= 0) return SLOT_STATUS.REFILL;
        return SLOT_STATUS.OCCUPIED;
    };

    const classes = useStyles({ is12slot: is12slots });
    const navigate = useNavigate();
    const status = getStatus();

    const renderProduct = () => {
        if(!product || Object.keys(product).length === 0) return "Add Product";
        return product.name || "";
    };

    const truncate = (input) => {
        if(input.length > 13) {
            return input.substring(0, 13) + "...";
        }
        return input;
    };

    if(disable) {
        return <div className={classes.root}></div>
    }

    return (
        <Box
            onClick={() => { const path = generatePath("/machines/:machineId/boards/:boardId/slots/:slotId", { machineId, boardId, slotId }); navigate(path) }}
            className={`slot ${status === SLOT_STATUS.OCCUPIED ? "occupied" : status === SLOT_STATUS.EMPTY ? "empty" : status === SLOT_STATUS.REFILL ? "refill" : status === SLOT_STATUS.ERROR ? "error" : "nonInitialized"}`}
        >
            <Typography variant="body2" className="slot-label">{slotName}</Typography>
            <Typography variant="body1" fontWeight="bold" className="product-name" mt={1}>
                {renderProduct()}
            </Typography>
        </Box>
    );
};

export default LockerItem;
