import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useRecordContext } from "react-admin";
import { useNavigate } from "react-router-dom";
import { Button, Menu, MenuItem } from "@material-ui/core";
import { db } from "../../firebase";
import { deleteDoc, doc } from "firebase/firestore";
import MoreIcon from "@material-ui/icons/ExpandMoreOutlined";

const useStyles = makeStyles((theme) => ({
    icon: {
      marginLeft: theme.spacing(0.5),
      width: 20,
    },
    menuItem: {
      padding: theme.spacing(1),
    },
    actionButton: {
      justifyContent: "flex-start",
      padding: theme.spacing(1, 2),
      width: "100%",
      textAlign: "left",
      "&:hover": {
        backgroundColor: theme.palette.action.hover,
      },
    },
}));

function ProActionMenu({ basePath, resource, children }) {
    const record = useRecordContext();
    const navigate = useNavigate();
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => setAnchorEl(event.currentTarget);
    const handleClose = () => setAnchorEl(null);

    const handleDelete = async () => {
        try {
            const empRef = await doc(db, "products", record.id);
            await deleteDoc(empRef);
            alert("Products Deleted");
            navigate("/products");
            setTimeout(() => navigate(0), 1000);
        } catch(err) {
            console.log("error: ", err);
        } finally {
            setAnchorEl(null);
        }
    };

    return (
        <>
            <Button
                aria-haspopup="true"
                onClick={handleClick}
                size="small"
                endIcon={<MoreIcon className={classes.icon} />}
            >
                More
            </Button>
            <Menu
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                {React.Children.map(children, (child, index) => (
                    <MenuItem
                        key={index}
                        disableGutters
                        className={classes.menuItem}
                        onClick={handleClose} // Close the menu when a child is clicked
                    >
                      {React.cloneElement(child, {
                          basePath,
                          record,
                          resource,
                          className: classes.actionButton,
                          fullWidth: true,
                          disableRipple: true,
                          variant: "text",
                          icon: null, // Optional: remove icons if unnecessary
                          to: child.type.name === "EditButton" ? `/products/edit/${record.id}` : undefined,
                          onClick: child.type.name === "DeleteButton" ? handleDelete : undefined,
                      })}
                  </MenuItem>
                ))}
            </Menu>
        </>
    );
}

export default ProActionMenu;