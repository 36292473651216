import { Checkbox, CircularProgress, FormControl, FormControlLabel, FormGroup, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react'
import { auth } from '../../firebase';
import { onAuthStateChanged } from 'firebase/auth';
import { useDataProvider } from 'react-admin';

const MROAccessGroupInput = ({ client, value, onValueChange, initialValue, ...rest }) => {
    const [loading, setLoading] = useState(true);
    const [options, setOptions] = useState([]);
    const [loginHasMROAccess, setLoginHasMROAccess] = useState(false);
    const [selectedValues, setSelectedValues] = useState([]);

    const dataProvider = useDataProvider();

    useEffect(() => {
        const checkAuth = onAuthStateChanged(auth, async (user) => {
            if(user) {
                await getCurrentUser(user.uid);
                await getEmployeeTypes();
            }
            setLoading(false);

            if(!value.length && initialValue?.length) {
                setSelectedValues(initialValue);
            } else {
                setSelectedValues(value);
            }
            
        });
        return () => checkAuth();
    }, [auth, client]);

    const getCurrentUser = async (userId) => {
        try {
            const res = await dataProvider.getOne("users", { id: userId });
            //check mro access
            const accesses = res.data.access;
            if(accesses.includes("mro")) {
                setLoginHasMROAccess(true);
            }
        } catch (err) {
            console.log(err);
        }
    };

    const getEmployeeTypes = async () => {
        try {
            const res = await dataProvider.getList("employeeTypes", {
                pagination: { page: 1, perPage: 99999 },
                sort: { field: "name", order: "ASC" },
                filter: { client },
            });
            
            const fetchedOptions = res.data.map(item => ({
                value: item.id,
                label: item.name,
            }));
            setOptions(fetchedOptions);
        } catch (err) {
            console.log(err);
        }
    };

    const handleValueChange = (updatedValues) => {
        setSelectedValues(updatedValues);
        onValueChange && onValueChange(updatedValues);
    };
    
    return (
        <>
            <Typography gutterBottom variant="h6">
                MRO Access
            </Typography>
            <Typography gutterBottom variant="body2">
                Select to enable access to employee group
            </Typography>
            {loading ? (
                <CircularProgress size={20} />
            ) : (
                <FormControl component="fieldset">
                    <FormGroup>
                        {options.map((option) => (
                            <FormControlLabel
                                key={option.value}
                                label={option.label}
                                control={
                                    <Checkbox
                                        name="mroAccessGroup"
                                        value={option.value}
                                        checked={selectedValues.includes(option.value)}
                                        onChange={(e) => {
                                            const { value: checkboxValue, checked } = e.target;
                                            let updatedValues = checked
                                                ? [...selectedValues, checkboxValue]
                                                : selectedValues.filter((v) => v !== checkboxValue)
                                            
                                            handleValueChange(updatedValues);
                                        }}
                                        disabled={!loginHasMROAccess}
                                        {...rest}
                                    />
                                }
                            />
                        ))}
                    </FormGroup>
                </FormControl>
            )}
        </>
    );
}

export default MROAccessGroupInput;
