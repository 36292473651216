import React from "react";
import { Box, Typography } from "@material-ui/core";
import { isEmpty } from "react-admin";
import { generatePath, useNavigate } from "react-router-dom";

const SLOT_STATUS = {
  DISABLE: 0,
  DEFAULT: 1,
  ERROR: 2,
  REFILL: 3,
  OCCUPIED: 4,
};

const SlotItemBoard = ({
  slotData,
  slotInformation,
  slotNumber,
  machineId,
  boardId,
  disable,
  is12slots,
}) => {
  const slotId = slotNumber;
  const slotName = slotInformation.label || "";
  const { maxCapacity, totalQuantity, minCapacity, error, product } = slotData;
  
  const navigate = useNavigate();
  
  const getStatus = () => {
    if (disable) return SLOT_STATUS.DISABLE;
    if (isEmpty(product) || isEmpty(slotData)) return SLOT_STATUS.DEFAULT;
    if (error) return SLOT_STATUS.ERROR;
    if (totalQuantity !== undefined && minCapacity !== undefined && totalQuantity <= minCapacity) return SLOT_STATUS.REFILL;
    return SLOT_STATUS.OCCUPIED;
  };

  const status = getStatus();

  const renderProduct = () => {
    if (!product || Object.keys(product).length === 0) return "Add product";
    return truncate(product.name || "");
  };

  const renderQuantity = () => {
    if (totalQuantity === undefined || !product) return "-";
    return maxCapacity ? `${totalQuantity}/${maxCapacity}` : `${totalQuantity}`;
  };

  const truncate = (input) => {
    if (input.length > 75) {
      return input.substring(0, 75) + "...";
    }
    return input;
  };
  if (disable) {
    return <div></div>;
  } else {
    return (
      <Box
          onClick={() => { const path = generatePath("/machines/:machineId/boards/:boardId/slots/:slotId", { machineId, boardId, slotId }); navigate(path) }}
          className={`slot ${status === SLOT_STATUS.OCCUPIED ? "occupied" : status === SLOT_STATUS.ERROR ? "error" : status === SLOT_STATUS.REFILL ? "ofs" : "empty"}`}
      >
          <Typography variant="body2" className="slot-label">{slotName}</Typography>
          <Typography variant="body1" fontWeight="bold" className="product-name" mt={1}>
              {renderProduct()}
          </Typography>
          <Typography variant="body2" className="quantity" mt={1}>
              {renderQuantity()}
          </Typography>
      </Box>
    );
  }
};

export default SlotItemBoard;
