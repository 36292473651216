import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { useGetDoc2UsLockerExpiredList, useGetDoc2UsLockerItems, useGetItemsFromMachines } from './RefreshMachineStatus';
import Doc2UsLockerItem from './Doc2UsLockerItem';
import { Card, CardContent, colors, Box } from '@material-ui/core';
import AdminCodeDialog from './AdminCodeDialog';

const useStyles = makeStyles(theme => ({
    row: {
      display: 'flex',
      marginBottom: theme.spacing(3),
    },
    rowLabel: {
      marginBottom: theme.spacing(1),
    },
    label: {
      width: '100%',
      marginBottom: 20,
      textAlign: 'right',
    },
    card: {
      overflowY: 'hidden',
      overflowX: 'scroll',
    },
    legendLabel: {
      minWidth: 80,
      minHeight: 30,
      display: 'inline-block',
      marginBottom: 2,
      marginRight: 10,
      fontSize: 14,
      fontWeight: 'bold',
    },
    legend: {
      minWidth: 80,
      minHeight: 30,
      display: 'inline-block',
      border: '1px solid',
      borderRadius: 10,
      marginRight: 7,
      marginBottom: 2,
      padding: 6,
      textAlign: 'center',
      fontSize: 12,
  
      '&.available': {
        backgroundColor: colors.green['A100'],
      },
      '&.pendingDeposit': {
        backgroundColor: colors.blue[100],
      },
      '&.pendingCollection': {
        backgroundColor: colors.orange[100],
      },
      '&.collecting': {
        backgroundColor: colors.blue[200],
      },
      '&.adminCheck': {
        backgroundColor: colors.grey[300],
      },
      '&.reserved': {
        backgroundColor: colors.yellow[100],
      },
      '&.expiredBeforeDeposit': {
        backgroundColor: colors.red[200],
      },
      '&.expiredBeforeCollection': {
        backgroundColor: colors.red[300],
      },
    },
    buttonContainer: {
      width: '100%',
      marginBottom: 14,
      textAlign: 'right',
    },
}));

const ALPHABET = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';

const reformatData = (numOfRow, slotList) => {
    let dataToReturn = [];
    let slotIDList = Object.keys(slotList);

    for (let i = 0; i < numOfRow; i++) {
        dataToReturn[i] = [];
    }

    slotIDList.forEach(idKey => {
        let dataToPass = slotList[idKey];
        dataToReturn[dataToPass.data.yPhysical - 1][dataToPass.data.xPhysical - 1] = dataToPass;
    });

    return dataToReturn;
};

const Doc2UsLockerGrid = ({ state, machineId, machineModel, boardData }) => {
    const classes = useStyles();

    const { data, loaded } = state;
    const isCompatMachine = machineModel === "VE-E1208N" || machineModel === "VE-E1208C";
    const itemsOnBoard = useGetItemsFromMachines(machineId, boardData.id);
    const dispenseTasks = useGetDoc2UsLockerItems(machineId);
    const listOfExpiredItems = useGetDoc2UsLockerExpiredList(machineId);
    
    let COL_NUM = boardData.xColumn;
    let ROW_NUM = boardData.yRow;

    if(!itemsOnBoard.loaded || !dispenseTasks.loaded || !listOfExpiredItems.loaded) return null;

    const formattedData = reformatData(ROW_NUM, itemsOnBoard.items);

    const Row = ({ rowIndex }) => {
        return (
            <div>
                <Box className="row">
                    {Array.from({ length: COL_NUM }).map((_, i) => {
                        const slotInformation = formattedData[rowIndex]?.[i] || {};
                        const slotIndex = rowIndex * COL_NUM + i;
                        const slotNumber = slotIndex + 1;
                        const slotData = dispenseTasks.items[slotInformation.id]?.data || {};

                        if(i >= 10) return null;

                        return (
                            <Doc2UsLockerItem
                                key={`slot-${slotIndex}-${i}`}
                                slotData={slotData}
                                slotNumber={slotNumber}
                                machineId={machineId}
                                boardId={boardData.id}
                                disable={false}
                                is12slots={false}
                            />
                        )
                    })}
                </Box>
            </div>
        );
    };

    const CompactRow = ({ rowIndex }) => {
        return (
            <div>
                <Box className={classes.row}>
                    {formattedData[rowIndex].map((slotInformation, index) => {
                        const slotIndex = rowIndex * COL_NUM + index;
                        const slotNumber = slotIndex + 1;
                        const slotData = dispenseTasks.items[slotInformation.id]
                          ? dispenseTasks.items[slotInformation.id].data
                          : {};
                        if(index >= 12) {
                            return null;
                        } else {
                            if(rowIndex > 2 && rowIndex < 6 && index > 7 && index < 13) {
                                <Doc2UsLockerItem
                                    key={slotIndex}
                                    slotData={slotData}
                                    slotNumber={slotNumber}
                                    slotInformation={slotInformation}
                                    machineId={machineId}
                                    boardId={boardData.id}
                                    disable={true}
                                    is12slots={true}
                                />
                            } else {
                                <Doc2UsLockerItem
                                    key={slotIndex}
                                    slotData={slotData}
                                    slotNumber={slotNumber}
                                    slotInformation={slotInformation}
                                    machineId={machineId}
                                    boardId={boardData.id}
                                    disable={false}
                                    is12slots={true}
                                />
                            }
                        }
                    })}
                </Box>
            </div>
        )
    };

    return (
        <div>
            <div className="label">
                <Box className="slot-status">Legends:</Box>
                <Box className={`available`}>Available</Box>
                <Box className={`pendingDeposit`}>Pending Deposit</Box>
                <Box className={`pendingCollection`}>Pending Collection</Box>
                <Box className={`collecting`}>Collecting</Box>
                <Box className={`adminCheck`}>Admin Check</Box>
                <Box className={`reserved`}>Reserved</Box>
                <Box className={`expiredBeforeDeposit`}>Expired Before Deposit</Box>
                <Box className={`expiredBeforeCollection`}>Expired Before Collection</Box>
            </div>
            <div className={classes.buttonContainer}>
                <AdminCodeDialog machineId={machineId} listOfExpiredItems={listOfExpiredItems} />
            </div>
            <Card className="card">
                <CardContent>
                    {Array.from({ length: ROW_NUM }, (_, i) => (
                        isCompatMachine ? <CompactRow rowIndex={i} key={`compact-row-${i}`} /> : <Row rowIndex={i} key={`row-${i}`} />
                    ))}
                </CardContent>
            </Card>
        </div>
    )
};

export default Doc2UsLockerGrid;
