import React from 'react'
import { Box, Button, CardHeader, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { StatusFieldInMachineInfo } from './MachinesStatus';
import { ReferenceField, TextField } from 'react-admin';
import { useRefreshMachineStatus } from './RefreshMachineStatus';

const useStyles = makeStyles(theme => {
    return {
        label: {
            marginRight: theme.spacing(0.5),
            minWidth: 120,
        },
        value: {
            ...theme.typography.body1,
            flex: 1,
        },
        fieldContainer: {
            display: "flex",
            alignItems: "center",
            marginBottom: theme.spacing(1),
        },
        fieldBox: {
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            marginRight: theme.spacing(1),
        }
    };
});

const MachineInfo = (props) => {
    
    const classes = useStyles();

    const [refreshing, refresh] = useRefreshMachineStatus(false);

    const Field = ({ label, value }) => {
        return (
          <Box mb={0.5} display="flex" className={classes.fieldContainer}>
            <Typography className={classes.label}>{label}:</Typography>
            <Typography className={classes.value}>{value ?? ""}</Typography>
          </Box>
        );
    };

    const { deviceID, machineUUID, machineName, machineMode, location } = props.macDetails || {};
    return (
        <>
            {props.macDetails && (
                <>
                    <div className={classes.fieldBox}>
                        <CardHeader title="Edit Machine" />
                        <Button loading={refreshing} onClick={() => refresh(props.id, Button)} color="primary" style={{ border: "1px solid blue" }} >Refresh Status</Button>
                    </div>
                    <Grid container>
                        <Grid item xs={12} md={6}>
                            <Field label="Device ID" source="deviceID" value={deviceID} />
                            <Field label="Machine UUID" source="machineUUID" value={machineUUID} />
                            <Field label="Machine name" source="machineName" value={machineName ?? ""} />
                            <Field label="Machine mode" source="machineMode" value={machineMode} />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Box className={classes.fieldContainer}>
                                <Typography className={classes.label} >Status:</Typography>
                                <StatusFieldInMachineInfo record={props.macDetails} variant="outlined" />
                            </Box>
                            <Box mb={1} className={classes.fieldContainer}>
                                <Typography className={classes.label}>Company:</Typography>
                                <ReferenceField source="client.id" reference="clients" record={props.macDetails} label="Company" link={false}>
                                    <TextField source="companyName" />
                                </ReferenceField>
                            </Box>
                            <Field label="location" source="location" value={location} />
                        </Grid>
                    </Grid>
                </>
            )}
        </>
    );
}

export default MachineInfo;
