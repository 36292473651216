import { Box, Chip, colors } from "@material-ui/core";
import React from "react";
import { isEmpty, useRecordContext } from "react-admin";

const statusColor = {
    "online": colors.green[500],
    "offline": colors.grey[500],
    "error": colors.red[500],
    "bill_error": colors.red[500],
    "coin_error": colors.red[500],
    "out_of_stock": colors.orange[500],
};

const StatusLabel = ({ status, variant }) => {
    const statusValue = status.replace(/\s+/g, "_");
    const color = statusColor[statusValue] || colors.grey[500];
    
    const chipStyle = variant === "outlined" ? {
        backgroundColor: "transparent",
        color: color,
        borderColor: color,
        borderStyle: "solid",
        borderWidth: 1,
    } : {
        backgroundColor: color,
        color: "#fff",
    };
    
    return (
        <Chip 
            label={status.toUpperCase().replace(/_/g, " ")} 
            style={chipStyle}
            size="small"
            variant={variant === "outlined" ? "outlined" : "default"}
        />
    )
};

export const StatusField = () => {
    const record = useRecordContext();
    
    if(!record || isEmpty(record?.status)) {
        return null;
    }
    
    const statuses = Object.keys(record.status).filter(
        (s) => !s.includes("terminal_error")
    );

    return(
        <Box display="flex" flexWrap="wrap" gap={0.5}>
            {statuses.map((status, index) => (
                <StatusLabel key={index} status={status} />
            ))}
        </Box>
    );
};

export const StatusFieldInMachineInfo = ({ record, variant }) => {
    if(!record?.status) return null;

    const { status } = record;
    
    const priorityStatuses = ["online", "offline", "error", "out_of_stock"];
    const upperline = [];
    let remainingStatuses = [];

    priorityStatuses.forEach((key) => {
        if(status[key]) {
            upperline.push(key);
        }
    });

    Object.keys(status).forEach((key) => {
        if (!priorityStatuses.includes(key) && status[key]) {
            remainingStatuses.push(key);
        }
    });

    return (
        <>
            <Box display="block" mb={0.5}>
                {upperline.map((item, index) => (
                    <StatusLabel key={index} status={item.replace(/_/g, " ")} variant={variant} />
                ))}
            </Box>
            <Box display="block">
                {remainingStatuses.map((item, index) => (
                    <StatusLabel key={index} status={item.replace(/_/g, " ")} variant={variant} />
                ))}
            </Box>
        </>
    )
}