import MachineInfo from './MachineInfo';
import _extendLayout from '../../layout/_extendLayout';
import SlotGrid from './SlotGrid';
import MachineSettings from './MachineSettings';
import SlotList from './SlotList';
import BoardTabs from './BoardTabs';
import React, { useEffect, useState } from 'react'
import { auth } from '../../firebase';
import { onAuthStateChanged } from 'firebase/auth';
import { useParams } from 'react-router-dom';
import { useDataProvider } from 'react-admin';
import { useGetBoardDataFromMachine, useGetSlotsRealtime, useGetMachineData, useGetApkVersion } from './RefreshMachineStatus';
import { Tabs, Tab, Divider, colors } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    tabs: {
      marginTop: theme.spacing(3),
    },
    divider: {
      backgroundColor: colors.grey[300],
    },
    content: {
      marginTop: theme.spacing(3),
    },
}));

const MachineEdit = (props) => {

    const { id } = useParams();
    const dataProvider = useDataProvider();
    const classes = useStyles();

    const [tab, setTab] = useState("slots");
    const [macDetails, setMacDetails] = useState(null);
    const boardData = useGetBoardDataFromMachine(id);
    const getSlotsState = useGetSlotsRealtime(id);
    const machineData = useGetMachineData(id);
    const version = useGetApkVersion(id);
    // const [refreshing, refresh] = useRefreshMachineStatus(false);
    const [isAdmin, setIsAdmin] = useState(false);

    useEffect(() => {
        if(boardData.loaded === true) {
            setTab(boardData.boards.length === 0 ? "slots" : "boards");
            getMachineDetails();
        }
    }, [boardData]);

    useEffect(() => {
        const checkAuth = onAuthStateChanged(auth, (user) => {
            if(user) {
                getCurrentUser(user.uid);
            }

            return () => checkAuth();
        })
    }, [auth]);

    const getCurrentUser = async (userId) => {
        try {
            const res = await dataProvider.getOne("users", { id: userId });
            setIsAdmin(res.data.role === "admin");
        } catch (err) {
            console.error("error:", err);
        }
    }

    const model = machineData?.data?.machineModel ?? null;
    const clientType = machineData?.data?.clientType ?? 1;

    const getMachineDetails = async () => {
        try {
            const res = await dataProvider.getOne("machines", { id });
            setMacDetails(res.data);
        } catch(err) {
            console.log("error:", err);
        }
    };

    const handleTabsChange = (event, newValue) => setTab(newValue);

    const setTabContainer = () => {

        return(
            <Tabs value={tab} onChange={handleTabsChange} className={classes.tabs}>
                {setSlotOrBoard(boardData.boards)}
                <Tab label="Products" value="products" />
                <Tab label="Settings" value="settings" />
            </Tabs>
        )
    };

    const setSlotOrBoard = (boardIdArray) => {
        if(boardIdArray.length === 0) {
            return <Tab label="Slots" value="slots" />;
        }

        return <Tab label="Boards" value="boards" />;
    };

    const setSlotOrBoardContent = (boardIdArray) => {
        if(boardIdArray.length === 0) {
            return tab === "slots" && <SlotGrid machineId={id} state={getSlotsState} machineModel={model} />;
        }
        return (
            tab === 'boards' && <BoardTabs machineId={id} state={getSlotsState} machineModel={model} boardData={boardData} clientType={clientType} />
        );
    };

    return (
        <>
            <_extendLayout>
                <div style={{ padding: "1rem" }}>
                    {macDetails && (
                        <MachineInfo macDetails={macDetails} id={id} />
                    )}
                    {setTabContainer(tab)}
                    <Divider className={classes.divider} />
                    <div className={classes.content}>
                        {setSlotOrBoardContent(boardData.boards)}
                        {tab === "products" && <SlotList machineId={id} state={getSlotsState} machineRecord={macDetails} />}
                        {tab === "settings" && <MachineSettings isAdmin={isAdmin} macDetails={macDetails} id={id} />}
                    </div>

                </div>
            </_extendLayout>
        </>
    );
}

export default MachineEdit;