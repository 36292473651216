import React from 'react'
import { Box, Card, CardContent, colors, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useGetItemsFromMachines, useGetSlotsRealtime } from './RefreshMachineStatus';
import SlotItemBoard from './SlotItemBoard';

const useStyles = makeStyles(theme => ({
    row: {
      display: 'flex',
      marginBottom: theme.spacing(3),
    },
    rowLabel: {
      marginBottom: theme.spacing(1),
    },
    label: {
      width: '100%',
      marginBottom: 20,
      textAlign: 'right',
    },
    legendLabel: {
      minWidth: 80,
      minHeight: 30,
      display: 'inline-block',
      marginBottom: 2,
      marginRight: 10,
      fontSize: 14,
      fontWeight: 'bold',
    },
    legend: {
      minWidth: 80,
      minHeight: 30,
      display: 'inline-block',
      border: '1px solid',
      borderRadius: 10,
      marginRight: 7,
      marginBottom: 2,
      padding: 6,
      textAlign: 'center',
      fontSize: 12,
  
      '&.occupied': {
        backgroundColor: colors.green['A100'],
      },
  
      '&.empty': {
        backgroundColor: colors.grey[100],
      },
  
      '&.error': {
        backgroundColor: colors.red[200],
      },
  
      '&.ofs': {
        backgroundColor: colors.orange[200],
      },
    },
}));

const ALPHABET = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';

const reformatData = (numOfRow, slotList) => {
    let dataToReturn = [];
    let slotIdList = Object.keys(slotList);

    for(let i = 0; i < numOfRow; i++) {
        dataToReturn[i] = [];
    };

    slotIdList.forEach(idKey => {
        let dataToPass = slotList[idKey];

        const yPhysical = parseInt(dataToPass?.data?.yPhysical, 10);
        const xPhysical = parseInt(dataToPass?.data?.xPhysical, 10);

        if (!isNaN(yPhysical) && !isNaN(xPhysical)) {
            // Ensure indices are within bounds
            if (yPhysical > 0 && yPhysical <= numOfRow) {
                dataToReturn[yPhysical - 1][xPhysical - 1] = dataToPass;
            } else {
                console.log(`Invalid yPhysical or xPhysical values: yPhysical=${yPhysical}, xPhysical=${xPhysical}`);
            }
        } else {
            console.log(`Invalid data for slot ID "${idKey}":`, dataToPass);
        }
        // dataToReturn[dataToPass.data.yPhysical - 1][dataToPass.data.xPhysical -1] = dataToPass;
    });

    return dataToReturn;
};

const SlotGridBoard = ({ machineId, machineModel, boardData }) => {
    const classes = useStyles();
    const isCompatMachine = machineModel === "VE-E1208N" || machineModel === "VE-E1208C";
    const itemOnBoard = useGetItemsFromMachines(machineId, boardData.id);
    const realtimeSlots = useGetSlotsRealtime(machineId);

    let COL_NUM = boardData.xColumn;
    let ROW_NUM = boardData.yRow;

    if(!itemOnBoard.loaded || !realtimeSlots.loaded) return null;

    const formattedData = reformatData(ROW_NUM, itemOnBoard.items);
    
    const Row = ({ rowIndex }) => {
        const rowNumber = ALPHABET[rowIndex]
        return(
            <div>
                <Typography className="row-label">Row {rowNumber}</Typography>
                <Box className="row">
                    {Array.from({ length: COL_NUM }).map((_, i) => {
                        const slotIndex = rowIndex * COL_NUM + i;
                        const slotNumber = slotIndex + 1;
                        const slotLabel = `${rowNumber}${i + 1}`;
                        const slotInformation = formattedData[rowIndex]?.[i]
                            ? { ...formattedData[rowIndex]?.[i], label: slotLabel}
                            : { id: slotLabel, label: slotLabel };
                        const slotData = slotInformation ? realtimeSlots.data[slotInformation.id] || {} : {};

                        if(i >= 10) return null;
                        
                        return (
                            <SlotItemBoard
                                key={`slot-${rowIndex}-${i}`}
                                slotData={slotData}
                                slotNumber={slotNumber}
                                slotInformation={slotInformation}
                                machineId={machineId}
                                boardId={boardData.id}
                                diablse={false}
                                is12slots={false}
                            />
                        )
                        
                    })}
                </Box>
            </div>
        )
    };

    const CompatRow = ({ rowIndex }) => {
        const rowNumber = ALPHABET[rowIndex]
        return (
            <div>
                <Typography className="row-label">Row {rowNumber}</Typography>
                <Box className="row">
                    {Array.from({ length: COL_NUM }).map((_, i) => {
                        const slotIndex = rowIndex * COL_NUM + i;
                        const slotNumber = slotIndex + 1;
                        const slotLabel = `${rowNumber}${i + 1}`;
                        const slotInformation = formattedData[rowIndex]?.[i]
                            ? { ...formattedData[rowIndex]?.[i], label: slotLabel}
                            : { id: slotLabel, label: slotLabel };
                        const slotData = realtimeSlots.data[slotInformation?.id] || {};

                        if(i >= 12) {
                            return null;
                        } else {
                            if(rowIndex > 2 && rowIndex < 6 && i > 7 && i < 13) {
                                return (
                                    <SlotItemBoard
                                        key={`slot-compat-${slotIndex}-${i}`}
                                        slotData={slotData}
                                        slotNumber={slotNumber}
                                        slotInformation={slotInformation}
                                        machineId={machineId}
                                        boardId={boardData.id}
                                        disable={true}
                                        is12slots={true}
                                    />
                                )
                            } else {
                                return (
                                    <SlotItemBoard
                                        key={`slot-compat-${slotIndex}-${i}`}
                                        slotData={slotData}
                                        slotNumber={slotNumber}
                                        slotInformation={slotInformation}
                                        machineId={machineId}
                                        boardId={boardData.id}
                                        disable={false}
                                        is12slots={true}
                                    />
                                )
                            }
                        }

                    })}
                </Box>
            </div>
        )
    };

    return (
        <div>
            <div className="label">
                <Box className="slot-status">Legends:</Box>
                <Box className={`occupied`}>Occupied</Box>
                <Box className={`error`}>Error</Box>
                <Box className={`ofs`}>Out Of Stock</Box>
                <Box className={`empty`}>Empty Slot</Box>
            </div>
            <Card className="card">
                <CardContent>
                    {Array.from({ length: ROW_NUM }, (_, i) => (
                        isCompatMachine ? <CompatRow rowIndex={i} key={`compat-row-${i}`} /> : <Row rowIndex={i} key={`row-${i}`} />
                    ))}
                </CardContent>
            </Card>
        </div>
    )
};

export default SlotGridBoard;
