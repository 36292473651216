import React, { useCallback, useEffect, useState } from "react";
import { isEmpty, useGetOne, useUpdate } from "react-admin";
import { db, realtimeDB } from "../../firebase";
import { collection, deleteDoc, deleteField, doc, getDoc, getDocs, onSnapshot, query, setDoc, updateDoc, where } from "firebase/firestore";
import { get, ref } from "firebase/database";

export const useRefreshMachineStatus = (notification = true) => {
    const [refreshing, setRefreshing] = useState(false);
    const undoable = false;
    const [update] = useUpdate("machines");

    const refresh = async (id) => {
        setRefreshing(true);
        
        const machineRef = doc(db, "machines", id);
        await updateDoc(machineRef, {
            "status.online": deleteField(),
            "status.offline": deleteField(),
        });
        
        const machineStatusRef = ref(realtimeDB, `/machines/${id}`);
        const snapshot = await get(machineStatusRef);
        const isConnected = snapshot.val()?.isConnected;
        
        await updateDoc(machineRef, {
            status: isConnected ? { online: true } : { offline: true },
        });

        const machineSnapshot = await getDoc(machineRef);
        const machineData = machineSnapshot.exists() ? machineSnapshot.data() : {};
        const status = machineData?.status || {};
        
        update(
            {
                id, 
                data: {
                    id, 
                    status,
                },
                previousData: machineData
            },
            {
                onSuccess: () => {
                    setRefreshing(false);
                    if(notification) {
                        // notify('Successful refresh machine status', 'info', { smart_count: 1 }, undoable);
                        alert("Success refresh machine status")
                    }
                },
                onFailure: () => {
                    setRefreshing(false);
                    if (notification) {
                        // notify('Failed to refresh machine status', 'error');
                        alert("Failed to refresh machine status")
                    }
                },
            }
        );
    };

    return [refreshing, refresh];
};

export const useGetBoardDataFromMachine = (machineId) => {
    const [boardData, setBoardData] = useState({ boards: [], loading: true, loaded: false });

    useEffect(() => {
        const boardsCollectionRef = collection(doc(collection(db, "machines"), machineId), "boards");
        const unsubscribe = onSnapshot(boardsCollectionRef, (querySnapshot) => {
            let boards = [];
            querySnapshot.forEach((doc) => {
                let boardDataToPush = doc.data();
                boardDataToPush.id = doc.id;
                boards.push(boardDataToPush);
            });
            setBoardData({ boards, loading: false, loaded: true });
        });
        return () => unsubscribe;
    }, [machineId]);

    return boardData;
};

export const useGetSlotsRealtime = (machineId) => {
    const [state, setState] = useState({ data: null, loading: true, loaded: false });

    useEffect(() => {
        const slotsCollectionRef = collection(doc(db, "machines", machineId), "slots");
        const unsubscribe = onSnapshot(slotsCollectionRef, (querySnapshot) => {
            const data = {};
            querySnapshot.forEach((doc) => {
                data[doc.id] = doc.data();
            });
            setState({ data, loading: false, loaded: true });
        });
        return unsubscribe;
    }, [machineId]);
    return state;
};

export const useGetSlots = (machineId) => {
    const [state, setState] = useState({ data: null, loading: true, loaded: false });
    const getSlots = useCallback(async () => {
        setState((prev) => ({ ...prev, loading: true }));

        try {
            const slotsCollectionRef = collection(db, "machines", machineId, "slots");

            const snapshots = await getDocs(slotsCollectionRef);
            const data = {};
            snapshots.forEach((doc) => {
                data[doc.id] = doc.data();
            });

            setState({ data, loading: false, loaded: true });
        } catch (err) {
            console.error("Error fetching slots data: ", err);
            setState({ data: {}, loading: false, loaded: false });
        }
    }, [machineId]);

    useEffect(() => {
        getSlots();
    }, [getSlots]);

    return state;
};

export const useGetSlot = (machineId, machineSlot) => {
    const path = `machines/${machineId}/slots/${machineSlot}`;
    return useGetSlotInfotmation(path);
};

export const useGetBoardSlot = (machineId, boardId, machineSlot) => {
    const path = `machines/${machineId}/boards/${boardId}/slots/${machineSlot}`;
    return useGetSlotInfotmation(path);
};

const useGetSlotInfotmation = (path) => {
    const [state, setState] = useState({ data: null, loading: true, loaded: false });
    
    const getSlot = async (docPath) => {
        try {
            const docRef = doc(db, ...docPath.split("/"));

            const docSnap = await getDoc(docRef);
            if(!docSnap.exists()) return null;
            return docSnap.data();
        } catch(err) {
            console.error("Error fetching slot information:", err);
            return null;
        }
    };

    useEffect(() => {
        setState({ data: null, loading: false, loaded: false });
        getSlot(path)
            .then((data) => setState({ data, loading: false, loaded: true }))
            .catch(() => setState({ data: null, loading: false, loaded: true }));
    }, [path]);
    
    return state;
};

export const useGetMachineData = (machineId) => {
    const [machineModel, setMachineModel] = useState({ data: null, loaded: false });

    const getMachineData = async (machineId) => {
        try {
            const docRef = doc(db, "machines", machineId);

            const docSnap = await getDoc(docRef);

            if(!docSnap.exists()) return null;
            return docSnap.data();
        } catch (err) {
            console.error("Error fetching machine data:", err);
            return null;
        }
    };

    useEffect(() => {
        setMachineModel({ data: null, loaded: false });
        getMachineData(machineId)
            .then((data) => setMachineModel({ data, loaded: true }))
            .catch(() => setMachineModel({ data: null, loaded: false }));
    }, [machineId]);

    return machineModel;
};

export const useGetApkVersion = (machineId) => {
    const [version, setVersion] = useState(0);

    const getVersion = async (machineId) => {
        try {
            const docRef = doc(db, 'machines', machineId);
      
            // Fetch the document snapshot
            const docSnap = await getDoc(docRef);

            // Check if the document exists and return versionCode if present
            if (docSnap.exists()) {
                return docSnap.data().versionCode;
            } else {
                return null;
            }
        } catch (err) {
            console.error("Error fetching APK version:", err);
            return null;
        }
    };

    useEffect(() => {
        getVersion(machineId)
          .then((versionCode) => setVersion(versionCode))
          .catch(() => setVersion(null));
    }, [machineId]);

    return version;
};

// useSaveSlot
export const useSaveSlot = () => {
    const [saving, setSaving] = useState(false);

    const save = async (machineId, data) => {
        setSaving(true);
        try{
            const slotRef = doc(db, `machines/${machineId}/slots/${data.machineSlot}`);
            await setDoc(slotRef, data, { merge: true });
        } catch (error) {
            console.error("Error saving slot: ", error);
        } finally {
            setSaving(false);
        }
    };

    return { saving, save };
};

// useRemoveSlot
export const useRemoveSlot = (machineId, machineSlot) => {
    const path = `machines/${machineId}/slots/${machineSlot}`;
    return useRemoveSlotInformation(path);
};

export const useRemoveSlotInformation = (path) => {
    const [removing, setRemoving] = useState(false);

    const remove = async () => {
        setRemoving(true);
        try {
            const slotRef = doc(db, path);
            await deleteDoc(slotRef);
        } catch(err) {
            console.error("Error removing document:", err);
        } finally {
            setRemoving(false)
        }
    };

    return { removing, remove };
};

export const useGetItemsFromMachines = (machineId, boardId) => {
    const [itemInSlots, setItemInSlots] = useState({ items: {}, loading: true, loaded: false });
    useEffect(() => {
        if(!machineId || !boardId == null) return null;
        const slotsCollectionRef = collection(db, `machines/${machineId}/boards/${boardId}/slots`);
        const unsubscribe = onSnapshot(slotsCollectionRef, (querySnapshot) => {
            const items = {};
            querySnapshot.forEach((docSnapshot) => {
                items[docSnapshot.id] = { id: docSnapshot.id, data: docSnapshot.data() };
            });
            setItemInSlots({ items, loading: false, loaded: true });
        });

        return () => {
            unsubscribe();
        }

    }, [machineId, boardId]);
    return itemInSlots;
};

export const useGetDoc2UsLockerItems = (machineId) => {
    const [doc2usLockerItemList, setDoc2usLockerItemList] = useState({ items: {}, loaded: false });
    useEffect(() => {
        if(!machineId) return;
        const dispenseTasks = collection(db, `machines/${machineId}/dispense_tasks`);

        const unsubscribe = onSnapshot(dispenseTasks, (querySnapshot) => {
            const items = {};
            querySnapshot.forEach((docSnapshot) => {
                const docData = docSnapshot.data();
                items[docData.slotId] = { id: docSnapshot.id, data: docData };
            });
            setDoc2usLockerItemList({ items, loaded: true });
        });

        return () => unsubscribe();
    }, [machineId]);

    return doc2usLockerItemList;
};

export const useGetDoc2UsLockerExpiredList = (machineId) => {
    const [state, setState] = useState({ expiredIDArray: null, loaded: false });
    useEffect(() => {
        if(!machineId) return;

        const dispenseTaskRef = collection(db, `machines/${machineId}/dispense_tasks`);
        const q = query(
            dispenseTaskRef,
            where("status", "in", ["ExpiredBeforeCollection", "ExpiredBeforeDeposit"])
        );

        const unsubscribe = onSnapshot(q, (querySnapshot) => {
            const idArray = [];
            querySnapshot.forEach((doc) => {
                idArray.push(doc.id);
            });

            setState({ expiredIDArray: idArray, loaded: true });
        });

        return () => unsubscribe();
    }, [machineId]);

    return state;
};

export const useSetAdminCodeOnMultipleLockerDoc2Us = () => {
    const [saving, setSaving] = useState(false);

    const save = async (machineId, listOfExpiredTaskId, adminCode) => {
        setSaving(true);
        try {
            const updatePromise = listOfExpiredTaskId.map((expiredTaskId) => {
                const taskDocRef = doc(db, `machines/${machineId}/dispense_tasks/${expiredTaskId}`);
                return updateDoc(taskDocRef, { adminCode });
            });

            await Promise.all(updatePromise);
        } catch (err) {
            console.error("Error updating admin code on tasks:", err);
        } finally {
            setSaving(false);
        };
    };

    return { saving, save };
};

export const useClientHas = (client, targetAccess) => {
    const { data: clientData, loaded: clientLoaded } = useGetOne("clients", { id: client || ""});
    const ownerId = clientData?.owner || null;
    const { data: userData, loaded: userLoaded } = useGetOne("users", { id: ownerId || "" });

    if(isEmpty(userData) || !checkAccess(userData, targetAccess)) return false;

    return true;
};

const AccessDenied = () => <div>Access Denied</div>;

export const ClientHas = ({ module, fallback = <AccessDenied />, client, children, ...props }) => {
    const hasAccess = useClientHas(client, module);
    
    if(!hasAccess) return fallback;

    return React.cloneElement(children, props);
};

export const checkAccess = (claims, targetAccess) => {
    if(claims.role === "admin") return true;
    const currAccess = claims?.access || [];
    return currAccess.includes(targetAccess);
};