import { Box } from '@material-ui/core';
import React from 'react'
import { useDataProvider, useRecordContext } from 'react-admin';
import { useNavigate } from 'react-router-dom';

const CategoryGroupField = ({ children, ...rest }) => {
    const record = useRecordContext();
    const dataProvider = useDataProvider();
    const navigate = useNavigate();

    const handleDelete = async () => {
        try{
            await dataProvider.delete("categories", { id: record.id });
            navigate("/categories");
            setTimeout(() => navigate(0), 1000);
        } catch (error) {
            console.log("error: ", error);
        }
    };
    
    return (
        <>
            <Box style={{display:"flex", flexDirection:"row", flexWrap:"wrap", justifyContent:"flex-end", alignItems:"center"}} gap={1}>
                {children.map((el, index) => (
                    <Box mx={1} key={index}>
                        {React.cloneElement(el, { 
                            record, 
                            ...rest, 
                            to: el.type.name === "EditButton" ? `/categories/edit/${record.id}` : undefined,
                            onClick: el.type.name === "DeleteButton" ? handleDelete : undefined,
                        })}
                    </Box>
                ))}
            </Box>
        </>
    );
}

export default CategoryGroupField;