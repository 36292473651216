import React, { useState } from "react";
import { makeStyles } from '@material-ui/core/styles';
import MoreIcon from "@mui/icons-material/ExpandMoreOutlined";
import { useRecordContext } from "react-admin";
import { useNavigate } from "react-router-dom";
import { Button, Menu, MenuItem } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    icon: {
      marginLeft: theme.spacing(0.5),
      width: 20,
    },
    menuItem: {
      padding: theme.spacing(1),
    },
    actionButton: {
      justifyContent: "flex-start",
      padding: theme.spacing(1, 2),
      width: "100%",
      textAlign: "left",
      "&:hover": {
        backgroundColor: theme.palette.action.hover,
      },
    },
}));

const MacActionMenu = ({ basePath, children, resource }) => {

    const record = useRecordContext();
    const navigate = useNavigate();
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => setAnchorEl(event.currentTarget);
    const handleClose = () => setAnchorEl(null);

    return (
        <>
            <Button
                aria-haspopup="true"
                onClick={handleClick}
                size="small"
                endIcon={<MoreIcon className={classes.icon} />}
            >
                More
            </Button>
            <Menu
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                {React.Children.map(children, (child, index) => (
                    <MenuItem
                        key={index}
                        disableGutters
                        className={classes.menuItem}
                        onClick={handleClose} // Close the menu when a child is clicked
                    >
                        {React.cloneElement(child, {
                            basePath,
                            record,
                            resource,
                            className: classes.actionButton,
                            fullWidth: true,
                            disableRipple: true,
                            variant: "text",
                            icon: null, // Optional: remove icons if unnecessary
                            to: child.type.name === "EditButton" ? `/machines/edit/${record.id}` : undefined,
                        })}
                    </MenuItem>
                ))}
            </Menu>
        </>
    );
}

export default MacActionMenu;
