import { Box, Button, colors, IconButton, TextField as MuiTextField } from '@material-ui/core';
import { Close as CloseIcon, Done as DoneIcon } from '@mui/icons-material';
import { doc, updateDoc, writeBatch } from 'firebase/firestore';
import React, { useRef, useState } from 'react'
import { Datagrid, FunctionField, ListContextProvider, NumberField, TextField } from 'react-admin';
import { db } from '../../firebase';
import { green } from "@material-ui/core/colors";
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    input: {
      padding: '5px 8px',
      width: 50,
    },
    topbar: {
      display: 'flex',
      justifyContent: 'space-between',
      marginBottom: 8,
    },
}));

const SlotList = (props) => {

    const { machineId, state = { data: {}, loaded: false }, machineRecord } = props;
    const { data, loaded } = props.state;

    const classes = useStyles();

    const transformedData = data && typeof data === "object"
        ? Object.entries(data).map(([key, value]) => ({ id: key, ...value }))
        : [];
    const ids = transformedData.length > 0 ? transformedData.map(item => item.id) : [];

    const statusColor = {
        ERROR: colors.red[500],
        REFILL: colors.orange[500],
    };

    const getSlotNum = (machineSlot) => {
        const ALPHABET = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
        const GRID_COL_NUM = 12;
        if(machineSlot % GRID_COL_NUM === 0) return `${ALPHABET[(machineSlot / GRID_COL_NUM) - 1]}12`
        return `${ALPHABET[parseInt(machineSlot / GRID_COL_NUM)]}${machineSlot % GRID_COL_NUM}`;
    };

    const useQuantityUpdate = (machineId, machineSlot) => {
        const slotNum = getSlotNum(machineSlot);
        const [updating, setUpdating] = useState(false);

        const update = async (value) => {
            if(/\d/.test(value)) {
                setUpdating(true);
                const machineDocRef = doc(db, "machines", machineId);

                try {
                    await updateDoc(machineDocRef, {
                        [`slots.${machineSlot}.totalQuantity`]: parseInt(value),
                    });
                    alert(`Quantity updated for slot ${slotNum}`)
                } catch (err){
                    alert(`Unable to update ${slotNum}'s quantity`)
                } finally {
                    setUpdating(false);
                }
            }
        };

        return {
            update,
            updating,
        };
    };

    const UpdateQtyButton = ({ record = {}, machineId }) => {
        const { maxCapacity, machineSlot } = record;
        const inputEl = useRef(null);
        const { update, updating } = useQuantityUpdate(machineId, machineSlot);
        const [showInput, setShowInput] = useState(false);
        const [inputError, setInputError] = useState('');

        const toggleInput = () => {
            setShowInput((prev) => !prev);
            setInputError("");
        };

        const onSave = (e) => {
            e.preventDefault();
            const inputVal = inputEl.current.value;

            if(inputVal < 0 || inputVal > maxCapacity) {
                setInputError("Invalid Value");
                return;
            }

            toggleInput();
            update(inputVal);
        };

        if(showInput) {
            return (
                <form onSubmit={onSave}>
                    <MuiTextField
                        type="number"
                        variant="outlined"
                        InputProps={{ classes: { input: classes.input } }}
                        inputProps={{
                            min: 0, 
                            max: maxCapacity,
                        }}
                        inputRef={inputEl}
                        error={!!inputError.length}
                        helperText={inputError}
                    />
                    <IconButton type="submit" size="small">
                        <DoneIcon style={{ color: green[600] }} />
                    </IconButton>
                    <IconButton onClick={toggleInput} size="small">
                        <CloseIcon color="error" />
                    </IconButton>
                </form>
            );
        } else {
            return <Button onClick={toggleInput} className="text-primary">Update QTY</Button>;
        }
    };

    const RefillAllButton = ({ record = {}, machineId }) => {
        const { maxCapacity, machineSlot } = record;
        const { update, updating } = useQuantityUpdate(machineId, machineSlot);
        return <Button onClick={() => update(maxCapacity)} className="text-primary">Refill All</Button>;
    };

    const RefillAllSlotsButton = ({ data = [], machineId }) => {

        const refillAll = async () => {
            try{
                const batch = writeBatch(db);
    
                Object.entries(data).forEach(([slotId, slotData]) => {
                    const { totalQuantity, maxCapacity } = slotData;
                    if(totalQuantity < maxCapacity) {
                        const slotRef = doc(db, `machines/${machineId}/slots/${slotId}`);
                        batch.update(slotRef, { totalQuantity: maxCapacity });
                    }
                });
    
                await batch.commit();
                alert('All slots refilled successfully')
            } catch (err) {
                alert("Refill error, please try again later");
            }
        };

        return <Button onClick={refillAll} className="text-primary">Refill all slots</Button>;
    };

    const ActionsCell = ({ record, machineId }) => (
        <Box display="flex" gap={1}>
            <UpdateQtyButton record={record} machineId={machineId} />
            <RefillAllButton record={record} machineId={machineId} />
        </Box>
    );

    const machineMode = props.machineRecord?.machineMode ?? "commercial";
    
    return (
        <>
            <div style={{ display: "flex", justifyContent:"flex-end" }}>
                <RefillAllSlotsButton data={data} machineId={machineId} />
            </div>
            <ListContextProvider value={{ data: transformedData, ids, loaded }}>
                <Datagrid hasBulkActions={false} resource="machines">
                    <FunctionField label="Slot No." render={record => getSlotNum(record.machineSlot)} />
                    <TextField label="Item No." source="itemNumber" sortable={false} />
                    <TextField label="Product Name" source="product.name" sortable={false} />
                    {machineMode === "mro" ? (
                        <NumberField source="product.priceCredit" label="Price (credit)" sortable={false} />
                    ) : (
                        <NumberField
                            source="product.priceCash"
                            label="Price (RM)"
                            locales="en-MY"
                            options={{ style: "currency", currency: "MYR" }}
                            sortable={false}
                        />  
                    )}
                    <FunctionField label="QTY" render={record => `${record.totalQuantity}/${record.maxCapacity}`} />
                    <FunctionField
                        label="Status"
                        render={record => {
                            const slotStatus = [];
                            if(record.totalQuantity <= 0) slotStatus.push("REFILL");
                            if(record.error) slotStatus.push("ERROR");

                            return (
                                <Box display="flex" gap={1}>
                                    {slotStatus.map((item, index) => (
                                        
                                        <Box
                                            key={index}
                                            style={{
                                                backgroundColor: statusColor[item] || colors.grey[500],
                                                color: "white",
                                                padding: "4px 8px",
                                                borderRadius: "4px",
                                                display: "inline-block",
                                            }}
                                        >
                                            {item}
                                        </Box>
                                    ))}
                                </Box>
                            )
                        }}
                    />
                    <FunctionField
                        label="Actions"
                        render={record => <ActionsCell record={record} machineId={machineId} />}
                    />
                </Datagrid>
            </ListContextProvider>
        </>
    );
}

export default SlotList;
